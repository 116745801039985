import React from "react"
import { Link } from "gatsby"
import useBlogData from "../static_queries/useBlogData"
import style from "./BlogList.module.scss"
import Img from "gatsby-image"

const BlogPost = ({ id, fields, frontmatter, excerpt }) => {
  const link = `/blog/${fields.slug}`
  return (
    <div className={style.item}>
      <Link to={link} className={style.picture__wrapper}>
        <Img
          fluid={frontmatter.hero_image.childImageSharp.fluid}
          alt={frontmatter.title}
        />
      </Link>
      <div className={style.post__body}>
        <h2>
          <Link to={link}>{frontmatter.title}</Link>
        </h2>
        <h3>{frontmatter.date}</h3>
        <p className={style.excerpt}>
          <Link to={link}>{excerpt.slice(0,-1)}</Link>
        </p>
        <Link to={link} className={style.readmore}>
          [read more]
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            x="0px"
            y="0px"
            viewBox="0 0 26 26"
            enableBackground="new 0 0 26 26"
          >
            <path d="M23.021,12.294l-8.714-8.715l-1.414,1.414l7.007,7.008H2.687v2h17.213l-7.007,7.006l1.414,1.414l8.714-8.713  C23.411,13.317,23.411,12.685,23.021,12.294z" />
          </svg>
        </Link>
      </div>
    </div>
  )
}

export default function BlogList() {
  const blogData = useBlogData()
  return (
    <>
        {
        blogData
          .filter(blog => blog.node.frontmatter.title !== "")
          .map(blog => {
            return <BlogPost key={blog.node.id} {...blog.node} />
          })
        }
    </>
  )
}
