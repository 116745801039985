import React from "react"
import Layout from "../components/Layout"
import BlogList from "../components/BlogList"
import SEO from '../components/SEO'

export default function IndexPage() {
  return (
    <Layout page="home" bgColor="inherit">
      <SEO/>
      <BlogList />
    </Layout>
  )
}